<template>
    <div>
    <h2>{{ $t('Login.SignUp.title') }}</h2>
    <b-alert v-model="hasErrors" variant="danger">
      {{ $t('Login.SignUp.error_message') }}
    </b-alert>
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
      <b-form-group
        id="input-group-1"
        :label="$t('Profile.your_email')"
        label-for="input-1"
        :invalid-feedback="getError('email')"
        :state="checkError('email')"
      >
        <b-form-input
          id="input-1"
          v-model="form.email"
          type="email"
          :placeholder="$t('Login.SignUp.email_adress_placeholder')"
          required
          @input="form.username=form.email"
          ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-6"
        label="Username:"
        label-for="input-6"
        :invalid-feedback="getError('username')"
        :state="checkError('username')"
      >
        <b-form-input
          id="input-6"
          v-model="form.username"
          type="text"
          :placeholder="$t('Login.SignUp.email_adress_placeholder')"
          disabled
          required
          ></b-form-input>
      </b-form-group>


      <b-form-group id="input-group-2" :label="$t('Profile.first_name')" label-for="input-2" :invalid-feedback="getError('first_name')"
        :state="checkError('first_name')">
        <b-form-input
          id="input-2"
          v-model="form.first_name"
          required  
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-3" :label="$t('Profile.last_name')" label-for="input-3" :invalid-feedback="getError('second_name')"
        :state="checkError('second_name')">
        <b-form-input
          id="input-3"
          v-model="form.second_name"
          required
        ></b-form-input>
      </b-form-group>

    <b-form-group id="input-group-4" :label="$t('Login.SignUp.password')" label-for="input-4" :invalid-feedback="getError('password')"
        :state="checkError('password')">
        <b-form-input
          id="input-4"
          v-model="form.password"
          required
          type="password"
        ></b-form-input>
      </b-form-group>

    <b-form-group id="input-group-5" :label="$t('Login.SignUp.password_confirm')" label-for="input-5"  :invalid-feedback="getError('password_confirm')"
      :state="checkError('password_confirm')">
        <b-form-input
          id="input-5"
          v-model="form.password_confirm"
          required
          type="password"
        ></b-form-input>
      
      </b-form-group>

      <b-form-group id="input-group-6">
        <b-form-checkbox-group
          v-model="datenschutz"
          id="checkboxes-4"
          :aria-describedby="ariaDescribedby"
        >
      <b-form-checkbox value="agree">
          <i18n path="Login.SignUp.agree_to_tos" tag="span">
            <a target="_blank" href="/wiki/Privacy">{{ $t('Login.SignUp.gdpr') }}</a>
          </i18n>
      </b-form-checkbox>
      </b-form-checkbox-group>
      </b-form-group>

      <b-alert :show="!ds" variant="warning">{{ $t('Login.SignUp.tos_warning') }}</b-alert>

      <b-button type="submit" :disabled="!ds" variant="primary">{{ $t('submit') }}</b-button>
      <b-button type="reset" variant="danger">{{ $t('reset') }}</b-button>
    </b-form>

  </div>
</template>

<script>
//import { apiService } from '../../services'

export default {
  name: 'SignUp',
  props: {
  },
  computed:{
    ds(){
      return this.datenschutz[0] === this.$t('Login.SignUp.agree')
    }
  },
  methods: {
      onSubmit(event) {
        this.form.username = this.form.email
        this.submitted = true;
        this.error = null;
        this.hasErrors = false;

        event.preventDefault()

        // POSTing the signup data to REST-API
        // Catching errors, displaying them inline the form.
        fetch(process.env.VUE_APP_REST_URL+'accounts/register/', {
            method: 'POST',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.form)
        })
        .then(response =>{
          if(response.status === 400){
            response.json()
              .then(body => {
                this.error = body;
                console.log(body);
              })
          }
          if(response.status === 201){
            response.json()
              .then(body => {
                this.reset();
                this.$emit('user-created', body);
              });
          }
        })
        .catch(error => {
          console.log(error); 
        })

      },
    reset() {
        this.form.email = '' 
        this.form.first_name = ''
        this.form.second_name = ''
        this.form.password = ''
        this.form.username = ''
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
        this.datenschutz = []
      },
    onReset(event) {
        event.preventDefault()
        this.reset();
      },
      getError(id){
        if(Object.prototype.hasOwnProperty.call(this.error,id)){
          this.hasErrors = true;
          return this.error[id].join(' ');
      }
    },
    checkError(id){
      return !Object.prototype.hasOwnProperty.call(this.error,id);
    },
  },
  data() {
      return {
        form: {
          email: '',
          username: '',
          first_name: '',
          second_name: '',
          password: '',
        },
        show: true,
        error: {},
        hasErrors: false,
        submitted: false,
        datenschutz: [],
      }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
