<template>

    <b-container >
    <b-alert v-if="signupSuccess" variant="success" show>
        {{ $t('Login.welcome_message', {first_name: signupUser.first_name})}}
    </b-alert>

    <b-alert v-if="qrError" variant="danger" show>
      {{$t('Login.qr_failed')}} <br />
      <small>{{$t('error_message')}}:</small>
      <pre>{{qrErrorMesesage}}</pre>
    </b-alert>

        <div>
            <b-card no-body>
                <b-tabs card  v-model="activeTab">
                <b-tab :title="$t('Login.login')">
                    <LoginComponent :user="user" />
                </b-tab>
                <b-tab v-if="!user" :title="$t('Login.sign_up')">
                    <b-card-text>    
                    <SignUp v-on:user-created="onUserCreated" />
                    </b-card-text>
                </b-tab>
                </b-tabs>
            </b-card>
        </div>

        <hr />

    </b-container>

</template>

<script>
import SignUp from '../components/login/SignUp';
import LoginComponent from '../components/login/Login';
import { userService } from '../services';

export default {
  name: 'Login',
  props: [
      'user',
  ],
  components: {
      SignUp,
      LoginComponent,
  },
  methods: {
      onUserCreated(user) {
          this.$router.push({name: "Dash"});
          this.signupSuccess = true
          this.signupUser = user
      },
  },
  data() {
      return {
          signupSuccess: false,
          signupUser: {},
          qrError: false,
          qrErrorMesesage: "",
      }
  },
  mounted(){
      if(this.$route.query.code){
          const code = this.$route.query.code
          userService.qrLogin(code)
            .then((response) => {
                if(response.access){
                const access = response.access
                const refresh = response.refresh
                this.$store.dispatch('authentication/loginQR', { access, refresh })
                if(this.$route.query.redirect)
                    this.$router.push(`/${this.$route.query.redirect}`)
                }
                
            })
            .catch((error) => {
                this.qrError = true
                this.qrErrorMesesage = error
            })
      }
  },
  computed: {
      activeTab: {
          get: function() {
          if(this.$route.name === "Login"){
              return 0;
          } else if(this.$route.name === "Signup"){
              return 1;
          } else if(this.$route.name === "SignupSuccess"){
              return 0;
          }
          
          return 0;
        },
        set: function(newValue, oldValue) {
            let states = ["login", "signup"]
            if(newValue != oldValue){
                this.$router.push({path: states[newValue]});
            }
        }
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
button{
    margin-right: 5px;
}
</style>
