<template>
    <div>
    <h2>Login</h2>
      <div v-if="!user">
      <b-alert v-model="hasErrors" variant="danger">
       {{ $t('Login.failed')}}
      </b-alert>
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <b-form-group
          id="input-group-username"
          :label="$t('Login.username')"
          label-for="input-username"
          :invalid-feedback="getError('login')"
          :state="checkError('login')"
        >
          <b-form-input
            id="input-username"
            v-model="form.username"
            type="text"
            :placeholder="$t('Login.your_email')"
            required
            ></b-form-input>
        </b-form-group>


      <b-form-group id="input-group-password" :label="$t('Login.your_password')" label-for="input-password" :invalid-feedback="getError('password')"
          :state="checkError('password')">
          <b-form-input
            id="input-password"
            v-model="form.password"
            required
            type="password"
          ></b-form-input>
        </b-form-group>

        <b-button type="submit" variant="primary">{{$t('Login.login')}}</b-button>
        <b-button type="reset" variant="danger">{{$t('reset')}}</b-button>
      </b-form>
    </div>
    <div v-else>
      {{$t('Dashboard.hello')}}, {{user.first_name}}!<hr />
      
      <b-button variant="primary" block v-on:click="logout" >{{$t('logout')}}</b-button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'LoginComponent',
  props: [
    'user',
  ], 
  methods: {
      onSubmit(event) {
        this.submitted = true;
        event.preventDefault()

        const username = this.form.username;
        const password = this.form.password;
        const { dispatch } = this.$store;
        if (username && password) {
            dispatch('authentication/login', { username, password })
        }

      },
    onReset(event) {
    
        event.preventDefault()
        this.form.password = '';
        this.form.username = '';
        this.show = false;
        this.hasErrors = false;
        this.$nextTick(() => {
          this.show = true
        })
      },
      logout(){
        const { dispatch } = this.$store;
        dispatch('authentication/logout');
      },
      getError(id){
        if(Object.prototype.hasOwnProperty.call(this.error,id)){
          this.hasErrors = true;
          return this.error[id].join(' ');
      }
    },
    checkError(id){
      return !Object.prototype.hasOwnProperty.call(this.error,id);
    },
  },
  data() {
      return {
        form: {
          username: '',
          password: '',
        },
        show: true,
        error: {},
        hasErrors: false,
        submitted: false,
      }
  },
  computed: {
    watchForErrors() {
      return this.$store.getters['authentication/error'];
    },
    loggedIn(){
      return this.$store.getters['authentication/loggedIn'];
    }
  },
  watch: {
    watchForErrors(error) {
      this.error = error;
      this.hasErrors = error ? true : false; 
    },
    user(){
      if(this.user && this.submitted)
        this.$router.push({name: "Dash"});
    }
  },

 }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
